import { onError } from "@apollo/client/link/error"
import { fromPromise } from "@apollo/client"

import axios from "axios"

import { setAuth } from "../../services/auth"
import { debug } from "../../services/log"

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    for (let err of graphQLErrors) {
      if (err.message === 'Token Expired') {
        return fromPromise(axios.post(String(process.env.GATSBY_API_URL), {
          query: `
          mutation RefreshToken {
            refreshToken {
              accessToken
              demo {
                id
              }
              user {
                id
              }
            }
          }
        `
        }, {
          withCredentials: true
        }).then(({ data }) => {
          const payload = data.data.refreshToken

          // reloads page (effectively sends to login page) if cannot refresh token
          if (!payload)
            return location.reload()
          
          // updates accessToken if refresh successful
          setAuth({
            demoId: payload.demo && payload.demo.id,
            userId: payload.user && payload.user.id, 
            accessToken: payload.accessToken
          })

          // return forward(operation)
        })).flatMap(() => forward(operation))
      }
    }

  // if (networkError) debug(`[Network error]: ${networkError}`);
})

export default errorLink
