import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client'

import { accessToken } from "../services/auth"
import errorLink from './links/error-link'

const authLink = new ApolloLink((operation, forward) => {
  const token = accessToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer: ${token}` : ``
    }
  }));
  return forward(operation);
})

const httpLink = new HttpLink({  
  uri: process.env.GATSBY_API_URL,
  credentials: 'include'
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    errorLink,
    authLink,
    httpLink
  ])
});
