import axios from "axios"

const API_URL = process.env.GATSBY_API_URL || ''
const LOG_QUERY = `
  mutation Log($input: LogInput!) {
    log(input: $input)
  }
`

export const debug = (message:string, data?:any) => {
  if (process.env.NODE_ENV != 'production') {
    console.debug(message, data)
  }
}

export const log = (message:string, data?:any) => {
  if (!API_URL) return

  let input: { message:string, data?:string } = { message }

  try {
    if (data) input.data = JSON.stringify(data)
  } catch {}

  axios.post(API_URL, {
    query: LOG_QUERY,
    variables: { input }
  }, {
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  }).catch(e => {
    debug('Error sending log', e)
  })
}
