import React from "react"

// Stripe
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Apollo
import { ApolloProvider } from "@apollo/client"
import { client } from "../apollo/client"

const stripePromise = loadStripe(String(process.env.GATSBY_STRIPE_PUBLISH_KEY));

export const wrapRootElement = ({ element }:{ element: React.ReactNode }) => (
  <Elements stripe={stripePromise}>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </Elements>
)
