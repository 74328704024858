import React from "react"
import { createRoot } from "react-dom/client"
import axios from "axios"

import { setAuth } from "./auth"
import { log } from "./log"

const API_URL = process.env.GATSBY_API_URL || ``

export const replaceHydrateFunction = () => {
  return (element:React.ReactNode, container:Element | DocumentFragment) => {
    axios.post(API_URL, {
      query: `
      mutation RefreshToken {
        refreshToken {
          accessToken
          demo {
            id
          }
          user {
            id
          }
        }
      }
    `
    }, {
      headers: { 'content-type': 'application/json' },
      withCredentials: true
    }).then(res => {
      const payload = res.data?.data?.refreshToken
      if (payload) {
        setAuth({
          demoId: payload.demo && payload.demo.id,
          userId: payload.user && payload.user.id, 
          accessToken: payload.accessToken
        });
      }
    }).catch(error => {
      document.cookie = 'auth_client=; Max-Age=1; Path=/' 
      log('Hydrate error', error)
    }).finally(() => {
      const root = createRoot(container)
      root.render(element);
    })
  };
};
