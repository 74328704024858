let _accessToken:string|null = null
let _currentDemoId:string|null = null
let _currentUserId:string|null = null

export function setAuth({
  demoId, userId, accessToken
}:{
  demoId:string|null, userId:string|null, accessToken:string
}) {
  _currentDemoId = demoId
  _currentUserId = userId
  _accessToken = accessToken
}

export function clearAuth() {
  _currentDemoId = null
  _currentUserId = null
  _accessToken = null
}

export function updateCurrentDemoId(demoId:string|null) {
  _currentDemoId = demoId
}

export function updateCurrentUserId(userId:string|null) {
  _currentUserId = userId
}

export function hasDemo() {
  return !!_currentDemoId
}

export function currentDemoId() {
  return _currentDemoId
}

export function isLoggedIn() {
  return !!_currentUserId
}

export function currentUserId() {
  return _currentUserId
}

export function accessToken() {
  return _accessToken
}